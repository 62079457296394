<template>
  <template-with-header>
    <main class="user-guide">
      <header
        class="user-guide__header"
        :class="{ 'user-guide__header--fixed content-container': isUserGuideMainPage }"
      >
        <div class="user-guide__breadcrumbs">
          <span class="breadcrumbs__link">
            <router-link :to="{ name: 'Dashboard' }" :title="$t('dashboard.action.home')">
              <span>{{ $t('dashboard.action.home') | upperCase }}</span>
            </router-link>
          </span>
          /
          <span class="breadcrumbs__current bold no-search-field">
            {{ userGuideLabel }}
          </span>
        </div>
        <div class="user-guide__search">
          <input
            v-model="searchQuery"
            type="text"
            class="search__input"
            :placeholder="$t('userGuide.label.searchTheUserGuide')"
            @keypress.enter="searchResults"
          />
          <span
            class="search__icon custom icon icon-search"
            :class="{ 'icon-search-active': !!searchQuery }"
            @click="searchResults"
          ></span>
        </div>
      </header>
      <div v-if="isUserGuideMainPage" class="user-guide__header-placeholder"></div>
      <router-view :key="$route.fullPath" />
    </main>
  </template-with-header>
</template>

<script>
import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';

import { USER_GUIDE_SEARCH_ALIASES } from '@/modules/dashboard/api/user-guide';
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'UserGuide',
  components: { TemplateWithHeader },
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    isUserGuideMainPage() {
      return this.$route.name === 'UserGuideMainPage';
    },
    userGuideLabel() {
      return isMobileDevice()
        ? this.$t('dashboard.label.mobile.userGuide')
        : this.$t('userGuide.label.userGuide');
    }
  },
  methods: {
    searchResults() {
      if (!this.searchQuery) {
        return;
      }

      const searchQuery =
        USER_GUIDE_SEARCH_ALIASES[this.searchQuery.toLowerCase()] || this.searchQuery;

      this.$router.push({ name: 'UserGuideSearch', params: { searchQuery } });
    }
  },
  metaInfo: {
    title: 'User Guide',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/templates/content-container';
@import '../../../assets/scss/helpers/colors';
@import '../../../assets/css/common/icons';

.user-guide {
  width: 100%;
  margin-bottom: 85px;

  &__header {
    margin: 30px auto;

    &--fixed {
      position: fixed;
      padding-top: 80px;
      padding-bottom: 30px;
      margin-bottom: 0;

      background-color: $background;
      z-index: 2;
    }
  }

  &__header-placeholder {
    height: 95px;
    margin: 31px 0;
  }

  &__breadcrumbs {
    margin-bottom: 30px;
  }

  &__search {
    position: relative;
  }
}

.search {
  &__input {
    height: 40px;
    width: 100%;

    padding: 0 50px 0 25px;

    background: $white;
    border: 1px solid $grey-color;
    border-radius: 50px;

    font-size: 15px;
    color: $text-color;
    outline: none;

    &::placeholder {
      color: $grey-color;
    }
  }

  &__icon {
    height: 18px;
    width: 18px;

    position: absolute;
    top: 11px;
    right: 16px;
  }
}

.breadcrumbs {
  &__link {
    font-size: 15px;
  }

  &__current {
    font-size: 15px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 767px) {
  .user-guide {
    width: 100%;
    padding: 0 4%;

    &__header {
      margin-bottom: 15px;
    }

    &__header-placeholder {
      margin-bottom: 32px;
    }
  }

  .content-container .user-guide {
    &__header {
      &--fixed {
        padding: 60px 4% 30px;
      }
    }
  }

  .search {
    &__input {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
