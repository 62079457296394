import UserGuideArticleConfig from '@/modules/dashboard/models/UserGuideArticleConfig';

export const SCROLL_SUBSECTION_OFFSET = -100;
export const SCROLL_SUBSECTION_OFFSET_MOBILE = -70;

export const USER_GUIDE_ARTICLE_NAME = {
  ABOUT_SKINXS: 'about-skinxs',
  PERSONAL_ACCOUNT: 'personal-account',
  DASHBOARD: 'dashboard',
  PATIENT: 'patient',
  INVITING_PATIENTS: 'inviting-patients',
  QUESTIONNAIRE: 'questionnaire',
  PHOTO_ANALYSIS: 'photo-analysis',
  SKIN_DIAGNOSTIC: 'skin-diagnostic',
  TREATMENT: 'treatment',
  LOYALTY_PROGRAM: 'loyalty-program',
  QUICK_LABEL_PRINT: 'quick-label-print',
  MESSAGE_TEMPLATES: 'message-templates',
  NOTIFICATIONS: 'notifications',
  GLOSSARY: 'glossary',
  CONCIERGE_SERVICE: 'online-concierge-service',
  MICRONEEDLING: 'microneedling',
  PRICE_SETTINGS: 'price-settings',
  TREATMENTS_DIRECTORY: 'treatments-directory'
};

export const SIDEBAR_SUBSECTION = {
  [USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS]: [
    'whatIsRational',
    'whatIsSkinxs',
    'whatAreBenefits',
    'dataPrivacy'
  ],
  [USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT]: ['accountDetails', 'howToResetPassword'],
  [USER_GUIDE_ARTICLE_NAME.DASHBOARD]: [
    'headerSection',
    'featuredFunctionsSection',
    'patientsList',
    'statuses',
    'searchFilterSection',
    'sorting',
    'quickAccessTool',
    'onlineSupportChat'
  ],
  [USER_GUIDE_ARTICLE_NAME.PATIENT]: [
    'createPatient',
    'contactPatient',
    'patientCard',
    'createTreatmentTab',
    'treatmentsHistory',
    'diagnosticsHistory',
    'medicalData',
    'photos',
    'comments'
  ],
  [USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS]: ['campaigns', 'sendAnInvite'],
  [USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE]: [
    'whatIsQuestionnaire',
    'sendQuestionnaire',
    'startQuestionnaire'
  ],
  [USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS]: ['howToStartPhotoAnalysis', 'photoAnalysisProcess'],
  [USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC]: [
    'diagnosticCreation',
    'diagnosticHistory',
    'dysfunctions',
    'pdfSummary',
    'anomaliesAndIssues',
    'algorithmRecommendations'
  ],
  [USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT]: [
    'accessPdfFromDashboard',
    'accessPdfFromPatientCard'
  ],
  [USER_GUIDE_ARTICLE_NAME.TREATMENT]: [
    'createTreatment',
    'treatSection',
    'complementaryProducts',
    'treatmentValidation',
    'treatmentsHistory',
    'renewTreatment'
  ],
  [USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM]: ['participants', 'skinPoints', 'redeemOptions'],
  [USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES]: [
    'marketingCampaigns',
    'customMarketing',
    'notifications'
  ],
  [USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS]: ['notificationBell', 'notificationsSentByEmail'],
  [USER_GUIDE_ARTICLE_NAME.GLOSSARY]: [
    'A',
    'C',
    'D',
    'F',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V'
  ],
  [USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE]: [
    'sendTreatmentOffer',
    'webshopOfferPage',
    'order',
    'consultationFee'
  ],
  [USER_GUIDE_ARTICLE_NAME.MICRONEEDLING]: [
    'microneedlingTreatment',
    'contraindicationsandLmitations'
  ],
  [USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS]: ['howToAccess', 'activeIngridientPrice'],
  [USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY]: ['usingTreatmentsDirectory', 'notices']
};

export const GLOSSARY_TERM_VALUE = {
  ACTIVE_INGREDIENT: 'activeIngredient',
  ANOMALY: 'anomaly',
  CAMPAIGN_MARKETINGS: 'campaignMarketings',
  CLINIC_LOCATIONS: 'clinicLocations',
  COMMENTS: 'comments',
  CONCENTRATION: 'concentration',
  COPY_LINK: 'copyLink',
  CREATE_PATIENT: 'createPatient',
  CREATE_TREATMENT: 'createTreatment',
  DASHBOARD: 'dashboard',
  DIAGNOSTIC: 'diagnostic',
  DYSFUNCTIONS: 'dysfunctions',
  FORMULA: 'formula',
  FORMULATION: 'formulation',
  LABEL: 'label',
  LOYALTY_PROGRAM: 'loyaltyProgram',
  LOYALTY_BONUS: 'loyaltyBonus',
  MEDICAL_DATA: 'medicalData',
  MENU: 'menu',
  MESSAGE_TEMPLATES: 'messageTemplates',
  NOTIFICATIONS: 'notifications',
  PATIENT_CARD: 'patientCard',
  PATIENT_LIST: 'patientsList',
  PATIENT_CONSENT: 'patientConsent',
  PDF_SUMMARY: 'pdfSummary',
  PHOTO_ANALYSIS: 'photoAnalysis',
  PRICE_SETTINGS: 'priceSettings',
  PRODUCT: 'product',
  PERSONAL_PHONE_NUMBER: 'personalPhoneNumber',
  PERSONALIZED_SERUM: 'personalizedSerum',
  QUICK_TREATMENT: 'quickTreatment',
  QUICK_LABEL: 'quickLabel',
  QUESTIONNAIRE: 'questionnaire',
  REBALANCE: 'rebalance',
  SERUM: 'serum',
  SECONDARY_EMAIL: 'secondaryEmail',
  STATUS: 'status',
  START_QUESTIONNAIRE: 'startQuestionnaire',
  STATISTICS: 'statistics',
  SUMMARY: 'summary',
  TREATMENT: 'treatment',
  TREAT_FACE: 'treatFace',
  TUTORIAL: 'tutorial',
  UNIQE_URL: 'uniqeUrl',
  VALIDATION: 'validation',
  VALIDATE_TREATMENT: 'validateTreatment'
};

export const GLOSSARY_TERMS_KEY_POSTFIXES = Object.values(GLOSSARY_TERM_VALUE);

export const GLOSSARY_DESCRIPTION_TYPE = {
  TEXT: 'TEXT',
  TEXT_WITH_LINKS: 'TEXT_WITH_LINKS'
};

export const ARTICLE_COMPONENT_CONFIG = {
  [USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS]: new UserGuideArticleConfig({
    name: 'AboutSkinxs',
    title: 'aboutSkinxs',
    icon: 'icon-skinxs-gray'
  }),
  [USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT]: new UserGuideArticleConfig({
    name: 'PersonalAccount',
    title: 'personalAccount',
    icon: 'icon-person'
  }),
  [USER_GUIDE_ARTICLE_NAME.DASHBOARD]: new UserGuideArticleConfig({
    name: 'DashboardGuide',
    title: 'dashboard',
    icon: 'icon-dashboard'
  }),
  [USER_GUIDE_ARTICLE_NAME.PATIENT]: new UserGuideArticleConfig({
    name: 'Patient',
    title: 'patient',
    icon: 'icon-patients'
  }),
  [USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS]: new UserGuideArticleConfig({
    name: 'InvitingPatients',
    title: 'invitingPatients',
    icon: 'icon-inviting-patients'
  }),
  [USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE]: new UserGuideArticleConfig({
    name: 'QuestionnaireGuide',
    title: 'questionnaire',
    icon: 'icon-questionnaire'
  }),
  [USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS]: new UserGuideArticleConfig({
    name: 'PhotoAnalysisGuide',
    title: 'photoAnalysis',
    icon: 'icon-photo-analysis-article'
  }),
  [USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC]: new UserGuideArticleConfig({
    name: 'SkinDiagnosticGuide',
    title: 'skinDiagnostic',
    icon: 'icon-skin-diagnostic'
  }),
  [USER_GUIDE_ARTICLE_NAME.TREATMENT]: new UserGuideArticleConfig({
    name: 'TreatmentGuide',
    title: 'treatment',
    icon: 'icon-treatment'
  }),
  [USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM]: new UserGuideArticleConfig({
    name: 'LoyaltyProgramGuide',
    title: 'loyaltyProgram',
    icon: 'icon-loyalty-program-article'
  }),
  [USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT]: new UserGuideArticleConfig({
    name: 'QuickLabelPrintGuide',
    title: 'quickLabelPrint',
    icon: 'icon-quick-label-print'
  }),
  [USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES]: new UserGuideArticleConfig({
    name: 'MessageTemplatesGuide',
    title: 'messageTemplates',
    icon: 'icon-message-templates'
  }),
  [USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS]: new UserGuideArticleConfig({
    name: 'NotificationsGuide',
    title: 'notifications',
    icon: 'icon-notifications'
  }),
  [USER_GUIDE_ARTICLE_NAME.GLOSSARY]: new UserGuideArticleConfig({
    name: 'Glossary',
    title: 'glossary',
    icon: 'icon-glossary'
  }),
  [USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE]: new UserGuideArticleConfig({
    name: 'OnlineConciergeService',
    title: 'onlineConciergeService',
    icon: 'icon-basket'
  }),
  [USER_GUIDE_ARTICLE_NAME.MICRONEEDLING]: new UserGuideArticleConfig({
    name: 'MicroneedlingGuide',
    title: 'microneedling',
    icon: 'icon-microneedling-user-guide'
  }),
  [USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS]: new UserGuideArticleConfig({
    name: 'PriceSettingsGuide',
    title: 'priceSettings',
    icon: 'icon-price-settings'
  }),
  [USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY]: new UserGuideArticleConfig({
    name: 'TreatmentsDirectoryGuide',
    title: 'treatmentsDirectory',
    icon: 'icon-treatments-directory'
  })
};

export const USER_GUIDE_SEARCH = {
  [USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS]: {
    name: USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS].title,
    subsections: [
      { title: 'whatIsRational' },
      { title: 'whatIsSkinxs' },
      { title: 'whatAreBenefits' },
      { title: 'dataPrivacy' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT]: {
    name: USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT].title,
    subsections: [
      {
        title: 'accountDetails',
        subtitles: [
          'name',
          'photo',
          'yourUniqueUrl',
          'yourWebsite',
          'country',
          'emailAddress',
          'secondaryEmailAddress',
          'officePhoneNumber',
          'personalPhoneNumber',
          'clinicLocations',
          'language',
          'statistics'
        ]
      },
      { title: 'howToResetPassword' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.DASHBOARD]: {
    name: USER_GUIDE_ARTICLE_NAME.DASHBOARD,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.DASHBOARD].title,
    subsections: [
      { title: 'headerSection', subtitles: ['logo', 'notificationsBell', 'burgerMenu'] },
      {
        title: 'featuredFunctionsSection',
        subtitles: ['createTreatment', 'invitePatients', 'quickLabelPrint']
      },
      { title: 'patientsList', subtitles: ['actionMenu', 'lastComment'] },
      { title: 'statuses' },
      { title: 'searchFilterSection', subtitles: ['search', 'filter'] },
      { title: 'sorting' },
      { title: 'quickAccessTool' },
      { title: 'onlineSupportChat', subtitles: ['howItWorks'] }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.PATIENT]: {
    name: USER_GUIDE_ARTICLE_NAME.PATIENT,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PATIENT].title,
    subsections: [
      {
        title: 'createPatient',
        subtitles: ['createPatientCard', 'howToAvoid']
      },
      { title: 'contactPatient' },
      {
        title: 'patientCard',
        subtitles: [
          'userpic',
          'status',
          'updatePatientProfile',
          'startPhotoAnalysis',
          'sendQuestionnaire'
        ]
      },
      { title: 'createTreatmentTab' },
      { title: 'treatmentsHistory' },
      { title: 'diagnosticsHistory' },
      { title: 'medicalData' },
      { title: 'photos', subtitles: ['comparePhotos'] },
      { title: 'comments' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS]: {
    name: USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS].title,
    subsections: [
      { title: 'campaigns', subtitles: ['facebook', 'email', 'sms', 'shareTheLink'] },
      { title: 'sendAnInvite' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE]: {
    name: USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE].title,
    subsections: [
      { title: 'whatIsQuestionnaire' },
      { title: 'sendQuestionnaire' },
      {
        title: 'startQuestionnaire',
        subtitles: ['startTheQuestionnaireFromDashboard', 'startTheQuestionnaireFromPatient']
      }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS]: {
    name: USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS].title,
    subsections: [
      {
        title: 'howToStartPhotoAnalysis',
        subtitles: ['photoAnalysisFromDashboard', 'photoAnalysisFromPatientCard']
      },
      {
        title: 'photoAnalysisProcess',
        subtitles: [
          'analysisResultsUpdate',
          'clarificationQuestions',
          'treatmentRecommendations',
          'diagnosticRecord',
          'howToUploadPicture'
        ]
      }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC]: {
    name: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC].title,
    subsections: [
      {
        title: 'diagnosticCreation',
        subtitles: [
          'questionnaireBasedDiagnostic',
          'howDiagnosticQuestionnaireCreated',
          'waysToViewQuestionnaire',
          'diagnosticBasedOnPhotoAnalysis',
          'howDiagnosticPhotoCreated',
          'waysToViewPhoto'
        ]
      },
      {
        title: 'diagnosticHistory',
        subtitles: ['diagnosticsPhoto']
      },
      {
        title: 'dysfunctions'
      },
      {
        title: 'pdfSummary'
      },
      {
        title: 'anomaliesAndIssues',
        subtitles: ['typesOfAnomalies', 'typesOfIssues']
      },
      {
        title: 'algorithmRecommendations'
      }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT]: {
    name: USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT].title,
    subsections: [{ title: 'accessPdfFromDashboard' }, { title: 'accessPdfFromPatientCard' }]
  },
  [USER_GUIDE_ARTICLE_NAME.TREATMENT]: {
    name: USER_GUIDE_ARTICLE_NAME.TREATMENT,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.TREATMENT].title,
    subsections: [
      {
        title: 'createTreatment',
        subtitles: [
          'treatmentFromScratch',
          'algorithmRecommendations',
          'createTreatmentNew',
          'createTreatmentExisting'
        ]
      },
      {
        title: 'treatSection',
        subtitles: ['treatFace', 'treatEye', 'universkinH']
      },
      { title: 'complementaryProducts' },
      {
        title: 'treatmentValidation',
        subtitles: [
          'reviewTreatment',
          'selectParticipant',
          'defineTreatment',
          'reviewSendTreatment',
          'printLabelOption'
        ]
      },
      { title: 'treatmentsHistory' },
      { title: 'renewTreatment' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM]: {
    name: USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM].title,
    subsections: [
      {
        title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM].title,
        subtitles: ['howToAccessLoyalty']
      },
      {
        title: 'participants',
        subtitles: ['addParticipant', 'participantStatus', 'deleteParticipant']
      },
      { title: 'skinPoints' },
      { title: 'redeemOptions' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES]: {
    name: USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES].title,
    subsections: [
      {
        title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES].title,
        subtitles: ['howToAccessPage']
      },
      { title: 'marketingCampaigns' },
      { title: 'customMarketing' },
      { title: 'notifications' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS]: {
    name: USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS].title,
    subsections: [
      { title: 'notificationBell', subtitles: ['howItWorks'] },
      { title: 'notificationsSentByEmail' }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.GLOSSARY]: {
    name: USER_GUIDE_ARTICLE_NAME.GLOSSARY,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.GLOSSARY].title,
    /* Subtitles are presented as subsections due to requirements */
    subsections: [
      { title: GLOSSARY_TERM_VALUE.ACTIVE_INGREDIENT },
      { title: GLOSSARY_TERM_VALUE.ANOMALY },
      { title: GLOSSARY_TERM_VALUE.CAMPAIGN_MARKETINGS },
      { title: GLOSSARY_TERM_VALUE.CLINIC_LOCATIONS },
      { title: GLOSSARY_TERM_VALUE.COMMENTS },
      { title: GLOSSARY_TERM_VALUE.CONCENTRATION },
      { title: GLOSSARY_TERM_VALUE.COPY_LINK },
      { title: GLOSSARY_TERM_VALUE.CREATE_PATIENT },
      { title: GLOSSARY_TERM_VALUE.CREATE_TREATMENT },
      { title: GLOSSARY_TERM_VALUE.DASHBOARD },
      { title: GLOSSARY_TERM_VALUE.DIAGNOSTIC },
      { title: GLOSSARY_TERM_VALUE.DYSFUNCTIONS },
      { title: GLOSSARY_TERM_VALUE.FORMULA },
      { title: GLOSSARY_TERM_VALUE.FORMULATION },
      { title: GLOSSARY_TERM_VALUE.LABEL },
      { title: GLOSSARY_TERM_VALUE.LOYALTY_PROGRAM },
      { title: GLOSSARY_TERM_VALUE.LOYALTY_BONUS },
      { title: GLOSSARY_TERM_VALUE.MEDICAL_DATA },
      { title: GLOSSARY_TERM_VALUE.MENU },
      { title: GLOSSARY_TERM_VALUE.MESSAGE_TEMPLATES },
      { title: GLOSSARY_TERM_VALUE.NOTIFICATIONS },
      { title: GLOSSARY_TERM_VALUE.PATIENT_CARD },
      { title: GLOSSARY_TERM_VALUE.PATIENT_LIST },
      { title: GLOSSARY_TERM_VALUE.PATIENT_CONSENT },
      { title: GLOSSARY_TERM_VALUE.PDF_SUMMARY },
      { title: GLOSSARY_TERM_VALUE.PHOTO_ANALYSIS },
      { title: GLOSSARY_TERM_VALUE.PRICE_SETTINGS },
      { title: GLOSSARY_TERM_VALUE.PRODUCT },
      { title: GLOSSARY_TERM_VALUE.PERSONAL_PHONE_NUMBER },
      { title: GLOSSARY_TERM_VALUE.PERSONALIZED_SERUM },
      { title: GLOSSARY_TERM_VALUE.QUICK_TREATMENT },
      { title: GLOSSARY_TERM_VALUE.QUICK_LABEL },
      { title: GLOSSARY_TERM_VALUE.QUESTIONNAIRE },
      { title: GLOSSARY_TERM_VALUE.REBALANCE },
      { title: GLOSSARY_TERM_VALUE.SERUM },
      { title: GLOSSARY_TERM_VALUE.SECONDARY_EMAIL },
      { title: GLOSSARY_TERM_VALUE.STATUS },
      { title: GLOSSARY_TERM_VALUE.START_QUESTIONNAIRE },
      { title: GLOSSARY_TERM_VALUE.STATISTICS },
      { title: GLOSSARY_TERM_VALUE.SUMMARY },
      { title: GLOSSARY_TERM_VALUE.TREATMENT },
      { title: GLOSSARY_TERM_VALUE.TREAT_FACE },
      { title: GLOSSARY_TERM_VALUE.TUTORIAL },
      { title: GLOSSARY_TERM_VALUE.UNIQE_URL },
      { title: GLOSSARY_TERM_VALUE.VALIDATION },
      { title: GLOSSARY_TERM_VALUE.VALIDATE_TREATMENT }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE]: {
    name: USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE].title,
    subsections: [
      { title: 'sendTreatmentOffer', subtitles: ['howToSendTreatmentOffer', 'treatmentsHistory'] },
      { title: 'webshopOfferPage' },
      { title: 'order' },
      { title: 'consultationFee', subtitles: ['howToAccessOnlineCosmetovigilance'] }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.MICRONEEDLING]: {
    name: USER_GUIDE_ARTICLE_NAME.MICRONEEDLING,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.MICRONEEDLING].title,
    subsections: [
      {
        title: 'microneedlingTreatment',
        subtitles: ['recommendedFormulas', 'settingUpProcedure', 'postprocedural']
      },
      { title: 'contraindicationsandLmitations', subtitles: ['contraindications'] }
    ]
  },
  [USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS]: {
    name: USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS].title,
    subsections: [{ title: 'howToAccess' }, { title: 'activeIngridientPrice' }]
  },
  [USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY]: {
    name: USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY,
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY].title,
    subsections: [
      {
        title: 'usingTreatmentsDirectory',
        subtitles: ['usingFromDashboard', 'usingAtPatientsCard']
      },
      { title: 'notices' }
    ]
  }
};

export const USER_GUIDE_SEARCH_ALIASES = {
  step: 's.tep'
};
